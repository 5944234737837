export default {
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
        REGION: "us-west-2",
        BUCKET: "notes-learningapp-uploads"
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://fegh65gffi.execute-api.us-west-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_GgYKjShBV",
        APP_CLIENT_ID: "6bto3ah2qtpj29rikdkdln4c1",
        IDENTITY_POOL_ID: "us-west-2:516b8c5b-1821-44e1-bf91-112a95356ee3"
    }
};